*, :before, :after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  padding: 0;
  font-family: Proxima Nova;
  font-weight: 400;
  text-decoration: none;
}

body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.widget-section, .widget-section .widget {
  width: 100%;
  height: 100%;
}

.widget-section .widget-instructions__content {
  width: 386px !important;
  padding: 30px 39px 25px !important;
}

@media screen and (width <= 670px) {
  .widget-section .widget-instructions__content {
    width: 280px !important;
    padding: 26px 20px 20px !important;
  }
}

.widget-section .widget-instructions__steps {
  column-gap: 26px !important;
  width: 100% !important;
}

.widget-section .widget-instructions__step {
  margin: 0 !important;
}

.widget-section .widget-instructions__step p {
  letter-spacing: -.02em !important;
  width: 100% !important;
  max-width: 100% !important;
}

.widget-section .widget-instructions__hint p {
  color: #413e3ecc !important;
  letter-spacing: -.02em !important;
  margin-top: 25px !important;
  font-size: 9px !important;
  font-weight: 600 !important;
  line-height: 12px !important;
}

.widget-section .widget-instructions button {
  margin-top: 30px !important;
}

@media screen and (width <= 670px) {
  .widget-section .widget-instructions button {
    margin-top: 25px !important;
  }
}

.floors-select {
  display: none !important;
}

.floors-new {
  display: block !important;
}

.hidden {
  display: none !important;
}
/*# sourceMappingURL=index.ab942eee.css.map */
