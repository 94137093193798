*, *:before, *:after
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-family: 'Proxima Nova'
  text-decoration: none
  font-weight: 400
  color: #fff
  outline: none
  padding: 0
  margin: 0
  box-sizing: border-box
  -webkit-box-sizing: border-box

body
  width: 100%
  height: 100%
  overflow: hidden

.widget-section
  width: 100%
  height: 100%
  .widget
    width: 100%
    height: 100%
    &-instructions
      &__content
        width: 386px !important
        padding: 30px 39px 25px 39px !important
        @media screen and (max-width: 670px)
          width: 280px !important
          padding: 26px 20px 20px 20px !important
      &__steps
        column-gap: 26px !important
        width: 100% !important
      &__step
        margin: 0 !important
        p
          letter-spacing: -0.02em !important
          width: 100% !important
          max-width: 100% !important
      &__hint
        p
          color: rgba(65, 62, 62, .8) !important
          font-size: 9px !important
          font-weight: 600 !important
          line-height: 12px !important
          letter-spacing: -0.02em !important
          margin-top: 25px !important
      button
        margin-top: 30px !important
        @media screen and (max-width: 670px)
          margin-top: 25px !important

.floors-select
  display: none !important

.floors-new
  display: block !important

.hidden
  display: none !important
